<template>
  <div>
    <div class="row">
      <transition name="fade">
        <div class="form-group" v-if="onProgress === false">
          <div class="input-group">
            <input @keyup.enter="search_text && search_text.length >= 1 ? debounceBoot() : null"
                   class="form-control"
                   id="search-text"
                   placeholder="Поиск"
                   type="text"
                   v-model="search_text">
            <label class="input-group-btn" for="search-text">
              <button :disabled="!search_text || (search_text && search_text.length < 1)"
                      class="btn btn-lg btn-primary"
                      title="Поиск по данным"
                      type="button"
                      v-on:click="debounceBoot()">
                <i class="fa fa-search"></i>
              </button>
            </label>
            <button class="btn btn-lg btn-default" title="Сбросить фильтры поиска" type="button"
                    v-if="isSearched" v-on:click="resetSearchText">
              <i class="fas fa-redo-alt"></i>
            </button>
          </div>
        </div>
        <loading-processing v-else/>
      </transition>
    </div>

    <div class="row">
      <transition name="fade">
        <div class="form-group" v-if="!shouldEmpty && onProgress === false">
          <div class="row">
            <label class="col-5 col-md-3" for="sorting">
              <select @change="setSorting($event)"
                      class="form-control"
                      id="sorting"
                      v-model="sorting">
                <option value="desc">Сначала новые</option>
                <option value="asc">Сначала старые</option>
              </select>

              <span v-if="total_items && shouldPaginate" class="pull-left medium-line-height mt-3 mb-1"
                    style="margin-left: 2px;">
                Всего записей: <strong class="font-medium">{{ total_items }}</strong>
              </span>
            </label>
            <label class="col-3 col-md-2" for="items-per-page">
              <select @change="setItemsPerPage($event)"
                      class="form-control"
                      id="items-per-page"
                      v-if="items && items.length >= 10"
                      v-model="items_per_page">
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </label>
            <div class="col-2 col-md-4"></div>
            <nav class="col-2 col-md-3">
              <ul class="pagination justify-content-end" v-if="shouldPaginate">
                <li class="page-item mx-3" v-bind:class="!hasPreviousPage ? 'disabled' : ''">
                  <a @click.prevent="prev" aria-label="Предыдущая" class="page-link"
                     title="Предыдущая страница" v-if="hasPreviousPage">
                    <i class="fas fa-backward"></i>
                  </a>
                </li>
                <li class="page-item" v-bind:class="!hasNextPage ? 'disabled' : ''">
                  <a @click.prevent="next" aria-label="Следующая" class="page-link"
                     title="Следующая страница" v-if="hasNextPage">
                    <i class="fas fa-forward"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </transition>
    </div>

    <div class="row">
      <transition name="fade">
        <div class="col-12" v-if="shouldEmpty">
          <div class="panel panel-default">
            <div class="panel-body text-center text-muted mt-5 mb-5">
              Нет элементов для отображения
            </div>
          </div>
        </div>
      </transition>
    </div>

    <transition-group name="fade" tag="div" class="row">
      <component v-if="!shouldEmpty && onProgress === false"
                 :class="item_classes"
                 :is="component"
                 :key="item.id"
                 v-bind:$_PaginationObject="item"
                 v-for="item in items">
      </component>
    </transition-group>

    <transition name="fade">
      <div class="row" v-if="shouldPaginate">
        <ul class="pagination justify-content-center pagination-lg">
          <li class="page-item mx-3" v-bind:class="!hasPreviousPage ? 'disabled' : ''">
            <a @click.prevent="prev" aria-label="Предыдущая" class="page-link"
               title="Предыдущая страница" v-if="hasPreviousPage">
              <i class="fas fa-backward"></i>
            </a>
          </li>
          <li class="page-item" v-bind:class="!hasNextPage ? 'disabled' : ''">
            <a @click.prevent="next" aria-label="Следующая" class="page-link"
               title="Следующая страница" v-if="hasNextPage">
              <i class="fas fa-forward"></i>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'card-items',

  props: {
    api_resource: {
      type: String,
      required: true,
    },
    component: {
      type: String,
      required: true,
    },
    item_classes: {
      type: String,
      required: false,
      default: 'col-lg-6 cars-grid-item"'
    },
    relations: {
      type: Array,
      required: true,
    },
    relations_count: {
      type: Array,
      required: false,
      default: null,
    },
    only_cols: {
      type: [Array, null],
      required: false,
    },
    filters_query: {
      type: Object,
      required: false,
    },
    data_search: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    items: null,
    url: null,
    total_items: null,
    paginate: {
      path: null,
      per_page: null,
      next_page_url: null,
      prev_page_url: null
    },
    search_text: null,
    sorting: api_request_params.filters_paginate.sorting,
    items_per_page: api_request_params.filters_paginate.items_per_page,
    is_paginated: api_request_params.filters_paginate.is_paginated,
    onProgress: true,
  }),

  created() {
    this.boot();
    this.debounceBoot = _.debounce(this.boot, 50);
  },

  computed: {
    shouldPaginate() {
      return this.paginate.next_page_url || this.paginate.prev_page_url;
    },
    shouldEmpty() {
      return this.items && this.items.length === 0 && this.onProgress === false;
    },
    hasPreviousPage() {
      return this.paginate.prev_page_url !== null;
    },
    hasNextPage() {
      return this.paginate.next_page_url !== null;
    },
    isSearched: function () {
      return !!this.search_text;
    },
  },

  methods: {
    boot() {
      this.EventBus.$off('card-filter-changed');
      this.EventBus.$off('card-resource-pagination-changed');

      if (this.data_search) {
        this.search_text = this.data_search;
      }
      this.onProgress = true;
      this.url = `${this.api_resource}`;

      this.load();
      this.listen();
    },
    async load() {
      try {
        if (!this.response) {
          this.items = null;

          const response = await API.apiClient.get(window.handleURL(this.url), {
            params: {
              relations: this.relations,
              relations_count: this.relations_count,
              filters_query: this.filters_query,
              search_text: this.search_text,
              is_paginated: this.is_paginated,
              items_per_page: this.items_per_page,
              sorting: this.sorting,
              only_cols: this.only_cols,
            }
          });

          await this.handleResponse(response);
        } else {
          await this.handleResponse(this.response);
        }

        this.onProgress = false;
      } catch (error) {
        errorHandler(error);
      }
    },
    async handleResponse({data}) {
      this.items = data.data;
      this.paginate = {
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        path: data.path,
        per_page: data.per_page,
      };

      if (data.next_page_url || data.prev_page_url) {
        const responseUrl = new URL(data.next_page_url ? data.next_page_url : data.prev_page_url);

        this.total_items = parseInt(responseUrl.searchParams.get('total'));
      } else {
        this.total_items = 0;
      }
    },
    next() {
      this.url = this.paginate.next_page_url;
      this.load();
    },
    prev() {
      this.url = this.paginate.prev_page_url;
      this.load();
    },
    setSorting(event) {
      this.sorting = event.target.value;
      this.load();
    },
    setItemsPerPage(event) {
      this.items_per_page = event.target.value;
      this.boot();
    },
    resetSearchText() {
      this.search_text = null;
      this.boot();
    },
    listen() {
      this.$on('card-refresh-pagination', this.load);
      this.EventBus.$on('card-filter-changed', this.load);
      this.EventBus.$on('card-resource-pagination-changed', this.resetSearchText);
    },
  }
}
</script>

<style scoped>

</style>
